"use strict";

$(function () {
    // Add CSRF token header to all ajax requests
    $.ajaxSetup({
        headers:{
            "X-CSRF-TOKEN":$('meta[name="_token"]').attr('content')
        }
    });


    //Initialize foundation
    $(document).foundation({
        reveal: {
            dismiss_modal_class: 'custom-close, .close-reveal-modal',
            root_element       : 'body',
            animation          : 'none'
        }
    });

    $('.custom-close, .close-reveal-modal').click(function(){
        $(this).closest('[data-reveal]').foundation('reveal', 'close');
    });

    // Open Modal
    $('body').on('click', '[data-modal]', function (e) {
        e.preventDefault();

        var url = $(this).data('modal');

        if (url == 'image') {
            $('#modal').addClass('fixed-modal').foundation('reveal', 'open');

            $('#modal').html('<div class="text-center"><img src="' + $(this).attr('href') + '" style="max-height: 400px;"></div>');
        }
        else {
            $('#modal').foundation('reveal', 'open', {
                url    : url,
                success: function (data) {
                    if (data.error == 1) {
                        window.location.href = baseUrl;
                        return;
                    }
                },
                error  : function () {
                    alert('An unknown error has occurred. Please reload the page and try again.');
                }
            }).on('closed.fndtn.reveal', function () {
                $(this).removeClass('fixed-modal').html('');
            });
        }
    });

    // Mark body while modal is open, used to lock scrolling in main.scss
    $('body')
        .on('opened.fndtn.reveal', function () {
            $(this).addClass('modal-open');
        })
        .on('closed.fndtn.reveal', function () {
            $(this).removeClass('modal-open');
        });

    //Filtering
    $('body').on('change', '[data-filter]', function (e) {
        e.preventDefault();

        var base = $(this).data('filter');
        var f    = $(this).val();

        if (f != '') {
            f = '?f=' + f;
        }

        window.location.href = base + f;
    });

    // Page search
    $('body').on({
        'search': function (e) {
            e.preventDefault();

            var base = $(this).data('search');
            var q    = $(this).val();

            if (q != '') {
                q = '?q=' + q;
            }

            window.location.href = base + q;
        }
    }, '[data-search]');

    /**
     * Handle user defined field verification on form labels
     */
    $('body').on('click','[data-verify-field]', function(e){
        e.preventDefault();
        var self = $(this);

        if(self.hasClass('busy')){
            return;
        }

        console.log(this.href);

        $.ajax({
            method:'put',
            url:this.href,
            context:self,
            beforeSend:function(){
                this.addClass('busy');
            },
            success:function(resp){
                toastr.info(resp.message, 'Status Updated');

                this.closest('label, [verified-icons]').toggleClass('verified', resp.field.verified);

                self.trigger('verified.field', resp.field);
            },
            complete:function(){
                this.removeClass('busy');
            }
        })
    });

    // Set timezone cookie if not present
    if(!$.cookie('timezone')){
        $.cookie("timezone", moment.tz.guess());
    }

    //set toastr default options
    toastr.options = {
        positionClass:"toast-bottom-right"
    };

    //load queued ready events, see header-script.js
    processReadyQueue();

    $( ".sortable" ).sortable({
        stop: function(event, ui) {
            var data = "";

            $(".sortable tr").each(function(i, el){
                data += $(this).data('id') + ',';
            });

            data = data.substring(0, data.length-1)
            $.post('/sponsors/reorder', {serialized_order: data});
        }
    });
});

// $(function(){
    
    // $( "#sortable" ).disableSelection();
// });